import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/pharmadocx/downloads.jpg";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative lg:w-6/12 mt-12 lg:mt-0 flex flex-col justify-center`;
const Table = tw.table`items-center mb-10 mx-auto border-separate max-w-screen-xl w-full `;
const TableRow = tw.tr`items-start bg-gray-200`;
const TableRowWithBorder = tw.tr`items-start `;
const TableData = tw.td`h-12 px-4 sm:px-2 text-left`;
const TableDataDate = tw.td`h-12`;
const TableHeadingLeft = tw.th`font-bold text-gray-100 text-lg md:text-xl border border-gray-300 rounded-l-lg bg-primary-500 h-12`;
const TableHeadingCenter = tw.th`font-bold text-gray-100 text-lg md:text-xl border border-gray-300 bg-primary-500 h-12`;
const TableHeadingRight = tw.th`font-bold text-gray-100 text-lg md:text-xl border border-gray-300 rounded-r-lg bg-primary-500 h-12`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const SubHeading = tw.h1`font-black text-lg md:text-xl leading-snug max-w-lg`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
const Item = tw.p`my-5 lg:my-4 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const DownloadButton = tw.button`w-full font-bold px-6 lg:px-10 py-2 rounded bg-primary-400 text-gray-100 hocus:bg-primary-600 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`overflow-auto xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;



export default ({
  heading = "Downloads",
  description = "We provide high quality documents for smoothening your plant setup journey! Checkout our youtube channel to see detailed tutorials made by our team of industry leaders. Contact us for all your docmentation needs!",
  primaryButtonText = "See all downloads",
  primaryButtonUrl = "#allDownloads",
  watchVideoButtonText = "Open Youtube",
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDownloadLink, setModalDownloadLink] = useState("");
  const [modalFileName, setModalFileName] = useState("");
  const [modalImage, setModalImage] = useState("")

  const toggleModal = (downloadLink, fileName, image) => {
    setModalDownloadLink(downloadLink)
    setModalFileName(fileName)
    setModalImage(image)
    setModalIsOpen(!modalIsOpen);
  }

  const tableData = [
    {
      fileName: "CDSCO checklist for MD5 and MD9",
      date: "13.02.2023",
      downloadLink: "https://drive.google.com/file/d/1Q3N1m9VUyi6x8JzpWfGoasWFQ_1PVlf_/view?usp=sharing",
      image: "https://i.ibb.co/K7PTf8d/Screenshot-from-2023-02-13-15-21-15.png"
    },
    {
      fileName: "CDSCO Checklist for Import License on MD15",
      date: "13.02.2023",
      downloadLink: "https://drive.google.com/file/d/1XKrNE9svOWoRJ8_aU45Y_FcCnqUmjK2g/view?usp=sharing",
      image: "https://i.ibb.co/zNpGzYc/Screenshot-from-2022-10-16-10-03-39.png"
    },
    {
      fileName: "CDSCO Notification for Medical Gloves",
      date: "13.09.2021",
      downloadLink: "https://drive.google.com/file/d/1DUamD3mdWIBUxDo5pN85FgDkakEsM-9r/view?usp=sharing",
      image: "https://i.ibb.co/LvZMTtd/Screenshot-from-2023-02-13-15-32-16.png"
    },
    {
      fileName: "CDSCO Notification G.S.R.777(E) Class A non sterile & non measuring medical devices exemption from license",
      date: "15.10.2022",
      downloadLink: "https://drive.google.com/file/d/1mXQyEoYTjV8JccvSUJNfAP0lT5plPI3A/view?usp=sharing",
      image: "https://i.ibb.co/zNpGzYc/Screenshot-from-2022-10-16-10-03-39.png"
    },
    {
      fileName: "CDSCO Notification G.S.R.754(E) Medical Devices Sales License",
      date: "03.10.2022",
      downloadLink: "https://drive.google.com/file/d/195KzKP1IJ8c9f7O0BDC85BrzXPN49BxC/view?usp=sharing",
      image: "https://i.ibb.co/8KHz16m/Screenshot-from-2022-10-04-10-11-03.png"
    },
    {
      fileName: "CDSCO Circular for extending Class A & B Medical Device license deadline - File No.29/Misc/03/2022-DC(257)",
      date: "30.09.2022",
      downloadLink: "https://drive.google.com/file/d/1_WVB8cWMvsY7dM9ATuHRozNHdxcgZwfo/view?usp=sharing",
      image: "https://i.ibb.co/tzNjkb9/Screenshot-from-2022-10-01-01-40-44.png"
    },
    {
      fileName: "Notified Medical Device Ebook Revision 2",
      date: "09.09.2022",
      downloadLink: "https://drive.google.com/file/d/1_Wvn57v2-QBxfvlImJr4s7PWqiEyhw8S/view?usp=sharing",
      image: "https://i.ibb.co/n7KNrKh/notified-Medical-Devices-Rev2.jpg"
    },
    {
      fileName: "New Drug List 1961-Sep. 2017",
      date: "07.09.2022",
      downloadLink: "https://drive.google.com/file/d/1ELhsO3trVqeXj6SKrFYya9sP1IBSf1UE/view?usp=sharing",
      image: "https://i.ibb.co/4YW26x1/Screenshot-from-2022-09-25-16-21-51.png"
    },
    {
      fileName: "Ayush Ayurvedic License Documents Checklist Haryana",
      date: "06.09.2022",
      downloadLink: "https://drive.google.com/file/d/1DImCIg80TJHavkXZXjz2Pxp1rntX_Ier/view?usp=sharing",
      image: "https://i.ibb.co/jGqNmdp/Screenshot-from-2022-09-25-16-25-54.png"
    },
    // {
    //   fileName: "Cosmetics License Document Checklist Haryana",
    //   date: "04.09.2022",
    //   downloadLink: "https://drive.google.com/file/d/1tHlNQVUaux0ZthlLAQ7Lmd2CAFM3hRIr/view?usp=sharing",
    //   image: "https://i.ibb.co/wgXcTdv/Screenshot-from-2022-09-25-16-50-50.png"
    // },
    // {
    //   fileName: "Cosmetics License Document Checklist Delhi",
    //   date: "04.09.2022",
    //   downloadLink: "https://drive.google.com/file/d/1D5918aojoZ9yOR7i8w9SIDXDWjm-nGH4/view?usp=sharing",
    //   image: "https://i.ibb.co/0YyQ0Yr/Screenshot-from-2022-09-25-16-52-58.png"
    // },
    {
      fileName: "FSSAI Checklist for Central License",
      date: "02.09.2022",
      downloadLink: "https://drive.google.com/file/d/1tEXxSFGJy9FxxXCLK5kNGJ02SIWTXoTT/view?usp=sharing",
      image: "https://i.ibb.co/YympZLt/Screenshot-from-2022-09-25-16-56-19.png"
    },
    {
      fileName: "Medical Devices License Document Checklist",
      date: "28.08.2022",
      downloadLink: "https://drive.google.com/file/d/1lWdsh6RhkSjRxmw-eIVXHcNO_aQhXABk/view?usp=sharing",
      image: "https://i.ibb.co/9tTmp3b/Screenshot-from-2022-09-25-17-00-45.png"
    },
    // {
    //   fileName: "Pharma Mfg License Form 25 28 Checklist Haryana",
    //   date: "27.08.2022",
    //   downloadLink: "https://drive.google.com/file/d/1FpgRQUfRJjEK8XKF-q_IKRZ0n5j_D1GP/view?usp=sharing",
    //   image: "https://i.ibb.co/cLgh0dw/Screenshot-from-2022-09-25-17-07-10.png"
    // },
    // {
    //   fileName: "Repacking Form 25 B Checklist",
    //   date: "26.08.2022",
    //   downloadLink: "https://drive.google.com/file/d/1itPCsfY488SocdF7UkU_Z1jolcLSuDVe/view?usp=sharing",
    //   image: "https://i.ibb.co/6yw7BJY/Screenshot-from-2022-09-25-17-10-19.png"
    // },
    {
      fileName: "Wholesale/Retail Drug License Checklist",
      date: "25.08.2022",
      downloadLink: "https://drive.google.com/file/d/1n98ZdBjNFFKocJEyNP0JANq93RopYNEu/view?usp=sharing",
      image: "https://i.ibb.co/1266rnR/Screenshot-from-2022-09-25-17-12-45.png"
    },
    
    
  ]

  const getTable = () => {
    return (
      <Table id="allDownloads">
        <TableRow>
          <TableHeadingLeft width="80%">Document</TableHeadingLeft>
          <TableHeadingCenter width="10%">Date</TableHeadingCenter>
          <TableHeadingRight width="10%">⬇</TableHeadingRight>
        </TableRow>
        {tableData.map(t => (
          <TableRow>
          <TableData><b>➤  {t.fileName}</b></TableData>
          <TableDataDate>{t.date}</TableDataDate>
          <TableData>
            <DownloadButton
              onClick={() =>
                toggleModal(
                  t.downloadLink, t.fileName, t.image
                )
              }
            >
              Download
            </DownloadButton>
          </TableData>
        </TableRow>
        ))}
      
      </Table>
    );
  };

  return (
    <>
      {/* <Header /> */}
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <PrimaryButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
              {/* <WatchVideoButton onClick={toggleModal}>
                <span className="playIconContainer">
                  <PlayIcon className="playIcon" />
                </span>
                <span className="playText">{watchVideoButtonText}</span>
              </WatchVideoButton> */}
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img css={imageCss} src={imageSrc} alt="Hero" />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        {getTable()}
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton style={{zIndex: 100}} onClick={() => toggleModal("", "")}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <TwoColContactUsWithIllustrationFullForm
              subheading={modalFileName}
              downloadLink={modalDownloadLink}
              imgSrc={modalImage}
            ></TwoColContactUsWithIllustrationFullForm>
            {/* <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" /> */}
          </div>
        </StyledModal>
      </Container>
    </>
  );
};
