import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeadingH1 } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeadingH1)`font-black mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-left`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 text-left`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 text-left text-primary-600`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 text-left`}
  }
  ul {
    ${tw`list-disc list-inside text-left mt-2`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-1 inline leading-normal text-left`}
      }
    }
  }
  ol {
    ${tw`list-decimal list-inside text-left mt-2`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-1 inline leading-normal text-left`}
      }
    }
  }
`;
export default ({ headingText = "Project Report" }) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
          <title>Project Report - Pharmadocx</title>
          <meta name="description" content="We provide you Project report with present market status, expected market demand, names and addresses of existing manufacturers"/>
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p><strong>Present Market Status, Expected Market Demand, Names and Addresses of Existing Manufacturers</strong></p>

            <p>
            Before setting up a plant it is important to first prepare and check Project Report. Project report provides information that how much land is required to construct the plant, how much square feet area is to be constructed, what will be the cost of the machine and building. How much working capital will be required? One shall set up a manufacturing unit after carefully studying the project report so that he can arrange required funds & get projection of expenses, sales and turnover & can decide feasibility of the plant. Pharmadocx provides the realistic project report. All data is correct & is of current date. Contents of Project Report are as following:
            </p>

            

            <h2>Beginning</h2>
            <p>
            Introduction of the Project, History of the Product, Use of the Product.
            </p>

            <h2>Market Survey</h2>
            <p>
              Puffer, Dry Powder Inhaler, Analgesic Inhaler, Asthama Inhalers,
              Metered Dose Inhaler with Spacer.
            </p>

            <h2>Cosmetics</h2>
            <p>Present Market Status, Expected Market Demand, Names and Addresses of Existing Manufacturers.</p>

            <h2>Plant & Machinery</h2>
            <p>List of Machines required in Production Section, Laboratory and Utility, Name of address of manufacturers of Machines used in manufacturing, Electric Load and Water requirements.</p>

            <h2>Raw Material & Packing Material</h2>
            <p>List of Raw Materials & Packing material required to set up the plant, address of their manufacturers & their present market price.</p>
           

            <h2>Manufacturing Techniques</h2>
            <p>Manufacturing formula for few products ( List of ingredients with quantity), step wise manufacturing process & flow chart.</p>

            <h2>Personnel Requirements</h2>
            <p>List of Chemists required section wise, other staff required i.e. accountant, skilled labour etc. How many people to be appointed? Monthly salary expenses.</p>

            <h2>Land & Building</h2>
            <p>
            How much land is required to set up the plant, how much area will have to be constructed, A sample map of the premises.
            </p>

            <h2>Financial Aspects</h2>
            <p>
            Cost of Land & Building, Cost of Plant & Machineries, Fixed Capital Investment, Working Capital, Project Cost, Capital Formation, Cost of Production, Profitability Analysis, Break Even Point, Cash Flow Statement for 5 to 10 Years, Depreciation Chart, Conclusion, Projected Balance Sheet, Land Man Ratio.
            </p>

            <h2>We can provide realistic project reports for following plants-</h2>
            <ol>
              <li>Allopathic Medicine Factory</li>
              <li>Metered Dose Inhaler & Vitralle</li>
              <li>Cosmetics</li>
              <li>Medical Devices</li>
              <li>Diagnostic Kit</li>
              <li>Surgical Dressings</li>
              <li>Repacking Of Pharmaceuticals</li>
              <li>Homoeopathic Preparations</li>
              <li>Ayurvedic & Sidha Medicines Preparations</li>
              <li>Unani Medicine Preparations</li>
              <li>Contact Lens Solutions, Intraocular Lenses, Trypan Blue, Sodium Cmc, Hpmc Prefilled Syringe</li>
              <li>Medical Grade Oxygen</li>
              <li>Food Plant</li>
              <li>Blood Bank, Blood Products</li>
              <li>Commercial Testing Laboratory, Government Approved Testing House</li>
              <li>Umbilical Tapes</li>
              <li>Applicator Cotton Sterile Tips</li>
              <li>Phenyl, Black Disinfectants, White Disinfectants.</li>
            </ol>

            




          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
