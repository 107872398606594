import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
// import EmailIllustrationSrc from "images/pharmadocx/downloadImages/notifiedMedicalDevicesRev2.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const DisabledButton = tw(PrimaryButtonBase)`inline-block mt-8 disabled:bg-gray-400 disabled:pointer-events-none`;

export default ({
  subheading = "filename",
  heading = <>Download</>,
  description = "Add your details and click the button below to start the download!",
  submitButtonText = "Download",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  downloadLink = "",
  imgSrc= "",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
    honeypot: "",
  });
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false)
  const [isResponseSubmitted, setIsResponseSubmitted] = useState(false);
  function getFormData(form) {
    var elements = form.elements;
    var honeypot;

    var fields = Object.keys(elements)
      .filter(function (k) {
        if (elements[k].name === "honeypot") {
          honeypot = elements[k].value;
          return false;
        }
        return true;
        // eslint-disable-next-line
      })
      .map(function (k) {
        if (elements[k].name !== undefined) {
          return elements[k].name;
          // special case for Edge's html collection
        } else if (elements[k].length > 0) {
          return elements[k].item(0).name;
        }
      })
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos && item;
      });

    var formData = {};
    fields.forEach(function (name) {
      var element = elements[name];

      // singular form elements just have one value
      formData[name] = element.value;

      // when our element has multiple items, get their values
      if (element.length) {
        var data = [];
        for (var i = 0; i < element.length; i++) {
          var item = element.item(i);
          if (item.checked || item.selected) {
            data.push(item.value);
          }
        }
        formData[name] = data.join(", ");
      }
    });
    formData.message = "File Download";
    formData.submit = "File Download";
    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
    formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

    return { data: formData, honeypot: honeypot };
  }

  let handleFormSubmit = (e) => {
    e.preventDefault(); // we are submitting via xhr below
    setDownloadButtonClicked(true)
    var form = e.target;
    var formData = getFormData(form);
    var data = formData.data;

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (formData.honeypot) {
      return false;
    }

    // disableAllButtons(form);
    var url = form.action;
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.responseText);
        setFormData({
          name: "",
          email: "",
          message: "",
          mobile: "",
        });
        setIsResponseSubmitted(true);
        window.open(downloadLink, "_blank", "noopener,noreferrer");
      } else {
        console.log(xhr.status);
      }
    };
    setDownloadButtonClicked(true)
    // url encode form data for sending as post data
    var encoded = Object.keys(data)
      .map(function (k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
      })
      .join("&");
    xhr.send(encoded);
  };

  let handleChange = (e) => {
    console.log("In change function");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // let image = require("images/pharmadocx/downloadImages/notifiedMedicalDevicesRev2.jpg");

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          {/* <img src={image.default}></img> */}
          <Image imageSrc={imgSrc==="" ? EmailIllustrationSrc : imgSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>File: {subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {isResponseSubmitted && (
              <>
                <Description>Thanks for downloading! Click <a style={{color: "blue"}} href={downloadLink} target="_blank">here</a> if download didn't start automatically...</Description>
              </>
            )}
            {!isResponseSubmitted && (<>
              {description && <Description>{description}</Description>}
              <Form
                method="POST"
                className="gform"
                onSubmit={handleFormSubmit}
                action="https://script.google.com/macros/s/AKfycbw1Cyltv6VDlj7vW3CDrTM3HpDOInxkPJTcvYPCr-Z-Tx03Gv8Ti1F5uaAkF53TCuUtsA/exec"
              >
              
                <Input
                  id="email-input"
                  required
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                />
                <Input
                  id="name-input"
                  required
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <Input
                  id="mobile-input"
                  required
                  type="mobile"
                  name="mobile"
                  placeholder="Mobile Number"
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {/* <Textarea name="message" placeholder="Your Message Here" /> */}
                {downloadButtonClicked 
                ? 
                <DisabledButton type="submit" value="Submit" disabled>
                    Downlading...
                </DisabledButton> 
                : 
                  <SubmitButton type="submit" value="Submit">
                    Download
                  </SubmitButton>
                }
              </Form></>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
