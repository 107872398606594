import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeadingH1 } from "components/misc/Headings";
import { Helmet } from "react-helmet";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeadingH1)`font-black mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-left`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 text-left`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 text-left text-primary-600`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 text-left`}
  }
  ul {
    ${tw`list-disc list-inside text-left mt-2`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-1 inline leading-normal text-left`}
      }
    }
  }
  ol {
    ${tw`list-decimal list-inside text-left mt-2`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-1 inline leading-normal text-left`}
      }
    }
  }
`;
export default ({ headingText = "Plant Setup Consultancy" }) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
          <title>Plant Setup Consultancy - Pharmadocx</title>
          <meta name="description" content="Our team will visit your site & understand what type of plant you
              want to set up. We then provide you map, provide source of
              machine, supervise construction & machine installation & in the
              end successfully get you the licence."/>
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            {/* <p>Last updated: April 21, 2020</p> */}

            <p>
              Our team will visit your site & understand what type of plant you
              want to set up. We then provide you map, provide source of
              machine, supervise construction & machine installation & in the
              end successfully get you the licence. Pharmadocx can provide
              complete consultancy to set up following plants.
            </p>

            <div style={{ display: "flex" }}>
              <ul style={{ width: "50%" }}>
                <strong>
                  <li>Allopathic Medicine Factory</li>
                  <li>Metered Dose Inhaler & vitralle</li>
                  <li>Cosmetics</li>
                  <li>Medical Devices</li>
                  <li>Diagnostic Kits</li>
                  <li>Surgical Dressings</li>
                  <li>Repacking of Pharmaceuticals</li>
                  <li>Homoeopathic Preparations</li>
                  <li>Ayurvedic & Sidha Medicines Preparations</li>
                  <li>Unani Medicine Preparations</li>
                </strong>
              </ul>
              <ul style={{ width: "50%" }}>
                <strong>
                <li>Contact Lens Solutions, Intraocular Lenses, Trypan Blue, Sodium CMC, HPMC prefilled syringe</li>
                <li>Medical Grade Oxygen</li>
                <li>Food Plant</li>
                <li>Blood Bank, Blood Products</li>
                <li>Commercial Testing Laboratory, Government Approved Testing House</li>
                <li>Umbilical Tapes</li>
                <li>Applicator cotton Sterile Tips</li>
                <li>Phenyl, Black Disinfectants, White Disinfectants</li>
                </strong>
              </ul>
            </div>

            <h2>Allopathic Medicine Factory</h2>
            <p>
              Tablets, Capsules, Soft Gelatin Plant, Syrup, Dry Syrup, Powder,
              External application viz. Cream, Ointment, Lotion, Dusting Powder,
              Gel, Pouch, Protein powder, Small volume Parenterals consisting of
              Injection in the form of Ampoule, Vials, Dry Injection, Large
              Volume Parenterals, Nasal Drops, Eye Drops / Ophthalmic Solution,
              Ear Drops, Prefilled syringes, Lypholised dry injection plant,
              Dedicated Oncology Plant, Dedicated Hormonal Plant, Medicated
              soap.
            </p>

            <h2>Metered Dose Inhaler & Vitralle</h2>
            <p>
              Puffer, Dry Powder Inhaler, Analgesic Inhaler, Asthama Inhalers,
              Metered Dose Inhaler with Spacer.
            </p>

            <h2>Cosmetics</h2>
            <ul>
              <li>
                Powders viz. Face Powder, cake makeup, compact powder, face
                packs, masks and rouges
              </li>
              <li>
                Creams, Herbal Face Cream, Under Eye Cream, Lotions, Emulsions,
                Pastes, Cleansing Milk, Sunscreen Lotion, Face Wash, Shampoos,
                Pomade, Brilliantine, Shaving Cream, Hair Oils.
              </li>
              <li>Nail Polishes, Nail Lacquers</li>
              <li>Lipsticks & Lip gloss</li>
              <li>Depilatories</li>
              <li>Eye Brows, Eye Liners & Eye Lashes</li>
              <li>Kajal & Surma</li>
              <li>Aerosol in the form of Deodorants, after shave lotion.</li>
              <li>
                Alcoholic fragrances in the form of Perfumes, Hanky Perfume.
                Itr, Scents etc.
              </li>
              <li>Hair Dyes, Hair Colour, Hair Blackening products,</li>
              <li>Tooth Powder, Tooth Paste, Gel Tooth Paste.</li>
              <li>Toilet Soap, bathing Soap.</li>
            </ul>

            <h2>Medical Devices</h2>

            <ol>
              <li>
                Sterile Disposable Perfusion Set, Sterile Intravenous set ( IV
                Set), Catheters, I.V. Cannula (Intra Venous Cannula), Three Way
                Stop Cock, Three Way Stop Cock as an accessory of I.V.
                Cannula/Catheter/Perfusion Set, Sterile Blood collection set (BT
                Set), Spring Needles, Sterile Hypodermic Syringe, Disposable
                Syringe, Insulin Syringes
              </li>
              <li>
                <strong>Orthopedic Implants</strong> viz. Locking Plates,
                Locking Screws, Bone Screws, Interlocking Nails, Nails Caps,
                Segmental Spinal System, Arthocopy Implants, Total Knee & Hip
                Replacement, Thompson Femoral Head, Internal Prosthetic
                Replacement, Hip Joint, Bone Cement
              </li>
              <li>
                <strong>Cardiothoracic And Vascular Surgery:- </strong>
                <br></br>
                <div style={{ display: "flex" }}>
                  <ul style={{ width: "50%" }}>
                    <li>Arterial Line Filter</li>
                    <li>AV Tubing</li>
                    <li>Blood Cardioplegia Delivery set</li>
                    <li>Cardioplegia Adapter- 2 Way</li>
                    <li>Cardioplegia Adapter- 4 way</li>
                    <li>Cardioplegia Connector</li>
                    <li>Cardioplegia Delivery System</li>
                    <li>Cardioplegia Delivery System</li>
                    <li>Chest Drainage Catheter</li>
                    <li>Custom Pack- Heart Lung Pack</li>
                    <li>Disposable Chest Drainage System (adult)</li>
                  </ul>
                  <ul style={{ width: "50%" }}>
                    <li>Disposable Chest Drainage System (Paediatric)</li>
                    <li>Gas Line Filter</li>
                    <li>Inter Coastal Drainage System</li>
                    <li>Lung Exerciser</li>
                    <li>On X Prosthetic Heart Valve</li>
                    <li>Purge Line with One Way Valve</li>
                    <li>Quick Prime Line</li>
                    <li>Rigid Suction Device</li>
                    <li>Chest Drainage Catheter With Trocar</li>
                    <li>Tie Wrap</li>
                    <li>Tubing Organiser</li>
                  </ul>
                </div>
              </li>
            </ol>

            <h2>Anasthesia Products</h2>
            <div style={{ display: "flex" }}>
              <ul style={{ width: "50%" }}>
                <li>Anesthesia Face Mask</li>
                <li>Anesthesia Face Mask (Sillicon)</li>
                <li>Anesthesia Face Mask (Black Rubber)</li>
                <li>Bacterial Rubber (0.3 Micron)</li>
                <li>Brain Circuit</li>
                <li>Paediatric Rees T-Pieces</li>
                <li>Catheter Mount</li>
                <li>Double Lumen Catheter Kit</li>
                <li>Endotracheal Tube (Cuffed)</li>
                <li>Endotracheal Tube (Plain)</li>
                <li>Guedel Airways</li>
                <li>Heart and Moisture Exchange Filter</li>
                <li>Nasal Oxygen Catheter</li>
                <li>Oxygen Mask</li>
                <li>3- Position Transducer Mount</li>
                <li>Holder for IV Pole</li>
                <li>Horizontal Mouth Holder</li>
                <li>Oxygen Recovery Kit</li>
              </ul>
              <ul style={{ width: "50%" }}>
                <li>Pressure Monitoring Kit</li>
                <li>Pressure Monitoring Line</li>
                <li>Pressure Transducer Dome</li>
                <li>Rebreathing Bags</li>
                <li>Resuscitation Bag</li>
                <li>Single Lumen Catheter Kit</li>
                <li>Spinal Needle</li>
                <li>Step Connector</li>
                <li>Suction Catheter</li>
                <li>Three Way Stopcock with Ext. Line</li>
                <li>Three way stop cock</li>
                <li>Touchy Needle</li>
                <li>Tracheostomy Tube (Cuffed)</li>
                <li>Tracheostomy Tube (Plain)</li>
                <li>Triple Lumen Catheter Kit</li>
                <li>Twin Oxygen Set</li>
                <li>Ventilator Circuit</li>
              </ul>
            </div>

            <h2>Transfusion And Dialysis</h2>
            <ul>
              <li>Dialysis Catheter Kit</li>
              <li>Haemodialysis Blood Tubing Set</li>
              <li>Paritoneal Dialysis Catheter</li>
              <li>Paritoneal Dialysis Set</li>
            </ul>

            <h2>General Surgery</h2>
            <ul>
              <li>Abdominal Drainage Kit</li>
              <li>Biopsy Needle</li>
              <li>Corrugated Drain Sheet</li>
              <li>Disposable Surgical Hygiene Wear</li>
              <li>Yankauer Type Handle</li>
              <li>Yankauer Type Handle</li>
              <li>Yankauer Type Handle With Filter</li>
            </ul>

            <h2>Gynaecology Products</h2>
            <ul>
              <li>MTP Cannula</li>
              <li>Silicon Venteuse Cup</li>
              <li>Umbilical Catheter</li>
              <li>Umbilical Cord Clamp</li>
            </ul>

            <h2>Urology Products</h2>
            <ul>
              <li>Male Catheter</li>
              <li>Nelaton Catheter</li>
              <li>Suprabic Balloon With Trocar Cannula</li>
              <li>Tur Set</li>
              <li>Urethral Catheter</li>
            </ul>

            <h2>Cardiology Products</h2>
            <ul>
              <li>Angio Kit</li>
              <li>Control Syringes</li>
              <li>Guide Wire</li>
              <li>High Pressure Line</li>
              <li>Introducer Set</li>
              <li>Introducer Needle</li>
              <li>Manifolds</li>
              <li>PTCA Kit</li>
            </ul>

            <h2>General Medical Disposable</h2>
            <ul>
              <li>Blood Transfusion Set</li>
              <li>Foley Catheter (Silliconised)</li>
              <li>Hourly Urine Measurement Unit</li>
              <li>Infant Feeding Tube</li>
              <li>Infant Mucous Extractor</li>
              <li>Intravenous Transfusion Set</li>
              <li>IV Line  Filter</li>
              <li>Levins Tube</li>
              <li>Measured Volume Burrete Set</li>
              <li>Ryles Tube</li>
              <li>Scalp Vein Set</li>
              <li>Syringe With Needle</li>
              <li>Transparent Gloves</li>
              <li>Urine Bag With Measuring Cup</li>
              <li>Urine Collecting Bag</li>
            </ul>

            <h2>Diagnostic Kit</h2>
            <p>Hemoglobin Estimation Kit, HIV detection kit, HIB detection kit, Malaria detection kit, Sugar Estimation kit etc.</p>

            <h2>Surgical Dressings</h2>
            <p>Bandages, Elastic Adhesive bandage BP, Absorbent Gauze, Cotton Crepe Bandage BP, Elastic Cotton Crepe Bandage, Eye Pad BP, Adhesive Tape USP, Rolled Bandage, Operation Dressing Pad, Gauze Ribbon 4 Fold, Abdominal Gauze Swab, Gauze Swab, Ortho Roll Cast Pad, Paraffin Gauze Dressing BP, Polyurethane Gel Dressing, Cotton Pads, Elasticated Tubular Bandage, Cotton and Rubber Elastic Bandage, Cohesive Bandage, Bandage Skin Traction, Orthopedic Cast Padding Bandage, Plaster of Paris Bandage BP, Adhesive First Aid Dressing, Extension Plaster.</p>

            <h2>Repacking Of Pharmaceuticals</h2>

            <h2>Homoeopathic Preparations</h2>
            <p>Mother Tinctures, External Tinctures, Mother solution section, Potency Preparation Section, Ointment & Lotion Section, Syrup and Tonics, Ophthalmic Preparations / Eye Drops, Eye Lotions section, Tablets.</p>

            <h2>Ayurvedic & Sidha Medicines Preparations</h2>
            <p>Anjani, Pisti, Churna, Nasya, Manjan, Lepa, Kwath Churan, Pills, Vatti, Gutika, Maitrai, Tablets, Kupi Pakva, Ksara, Parpati, Lavana, Bhasma Satva, Sindura, Karpu, Uppu, Param, Kajal, Capsules, Ointment, Marham, Pasai, Pak, Avaleh, Khand, Modak, Lakayam, Panak syrup, Pravahi, Kwath Manapaku. Asava, Aristha, Sura, Ark Tinir, Tail, Ghrit Nay, Aschyotan, Netra Malham Panir, Karn, Bindu, Nasabindu. Eye Drops, Injections, Herbal Tonics, Herbal Syrups, Herbal Hair Cleanser, Herbal Body Cleanser, Body Shampoo,</p>

            <h2>UNANI MEDICINE PREPARATIONS</h2>
            <p>Itrifal Tiryaq, Majoon, Arq, Habb ( Pills) and tablets, Sufoof (powder), Raughan, Shiyaf, Surma, Kajal, Marham, Zimad ( ointment), Qurs ( Tablet), Kushta, Murabba, Capsule, Sharbat & Joshanda, Qutoor-e-Chasm and Marham ( Eye Drops, Eye Ointment),</p>

            <h2>Contact Lens Solutions, Intraocular Lenses, Trypan Blue, Sodium Cmc, Hpmc Prefilled Syringe</h2>

            <h2>Medical Grade Oxygen</h2>

            <h2>Food Plant</h2>
            <p>Food Plant viz. Protein Powder, Protein Granules, Protein Syrup, Multivitamins & Minerals Syrup, Calcium Syrup, Tablets, Capsules.</p>

            <h2>Blood Bank, Blood Products</h2>

            <h2>Commercial Testing Laboratory, Government Approved Testing House</h2>

            <h2>Umbilical Tapes</h2>

            <h2>Applicator Cotton Sterile Tips</h2>

            <h2>Phenyl, Black Disinfectants, White Disinfectants.</h2>




          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
