import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Gallery from "components/features/FiveColGallery.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Footer from "components/footers/FiveColumnWithBackground";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import ContactUs from "components/forms/TwoColContactUsWithIllustration"
import Header from "../components/headers/light";
import Youtube from "components/features/Youtube";
import imageSrc1 from "../images/pharmadocx/topbanner.png";


const HighlightedText = tw.span`text-primary-500`

class Home extends Component {

    render() {
        return ( 
        <AnimationRevealPage disabled>
            <Header />
            <Helmet>
                <title>Pharmadocx - Pharmaceutical, Cosmetics, Medical Device Consultant</title>
                <meta name="description" content="Pharmadocx is the industry leader providing consultancy services for setting up Allopathic, Ayurvedic, Cosmetics & Medical Devices factories in India. Contact us to setup your plant now."/>
            </Helmet>
            {/* <img src={imageSrc1} alt="top banner - visit us at cosmotech home expo, pragati maidan, delhi" style={{paddingLeft: "5px", maxWidth: "100%", maxHeight: "100%", borderRadius: "10px"}} /> */}
            <Hero />
            <FeatureStats />
            <Gallery />
            <Features
                heading={<>Our <HighlightedText>Services</HighlightedText></>}
            />
            <ContactUs />
            <ContactUsForm />
            <Youtube />
            <Testimonial textOnLeft={true} />
            <Footer />
        </AnimationRevealPage>
        )
    }
}

export default Home;