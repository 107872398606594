import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import imageSrc1 from "../../images/pharmadocx/machines.jpg";
import imageSrc2 from "../../images/pharmadocx/image1.jpg";
import imageSrc4 from "../../images/pharmadocx/image3.jpg";
import imageSrc5 from "../../images/pharmadocx/image4.jpg";
import imageSrc6 from "../../images/pharmadocx/image5.jpg";
import imageSrc7 from "../../images/pharmadocx/image6.jpg";
import imageSrc8 from "../../images/pharmadocx/image7.jpg";


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-8 h-8`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-96 flex justify-center mb-1`}
  }
`;
const Card = styled.div(props=>[
  // `height: 100%;`,
  tw`flex! flex-col sm:border min-w-full max-w-lg h-96  sm:rounded-tl-3xl sm:rounded-br-3xl relative focus:outline-none`
]
  )
// const CardImage = styled.div(props => [
//   `background-image: url("${props.imageSrc}"); min-width: fit-content;
//   `,
//   tw`h-full sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-3xl`
// ]);

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: imageSrc6,
      title: "Hudak Homes",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
      alt: "pharmaceutical factory consultant"
    },
    {
      imageSrc: imageSrc7,
      title: "Hudak Homes",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
      alt: "cosmetics factory consultant"
    },
    {
      imageSrc: imageSrc8,
      title: "Hudak Homes",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
      alt: "medical device factory consultant"
    },
    {
      imageSrc: imageSrc1,
      title: "Wyatt Residency",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Rome, Italy",
      pricingText: "USD 39/Day",
      rating: "4.8",
      alt: "ayurvedic factory consultant"
    },
    {
      imageSrc: imageSrc5,
      title: "Soho Paradise",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Ibiza, Spain",
      pricingText: "USD 50/Day",
      rating: 4.9,
      alt: "sanitiser factory consultant"
    },
    {
      imageSrc: imageSrc4,
      title: "Hotel Baja",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Palo Alto, CA",
      pricingText: "USD 19/Day",
      rating: "5.0",
      alt: "plant setup consultant"
    },
    {
      imageSrc: imageSrc2,
      title: "Hudak Homes",
      description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
      alt: "pharmaceutical consultant"
    },
  ]

  return (
    <Container id="our-work">
      <Content>
        <HeadingWithControl>
          <Heading>Our Work</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <img src={card.imageSrc} alt={card.alt} style={{paddingLeft: "5px", minHeight: "100%", minWidth: "100%", borderRadius: "inherit"}} />
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
