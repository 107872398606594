import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import {  Subheading as SubheadingBase } from "components/misc/Headings.js";
const Subheading = tw(SubheadingBase)`text-center md:text-left`;

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-8 h-8`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-72 flex justify-center mb-1`}
  }
`;
const Card = styled.div(props=>[
  // `height: 100%;`,
  tw`flex! flex-col sm:border min-w-full max-w-lg sm:rounded-tl-3xl sm:rounded-br-3xl relative focus:outline-none`
]
  )
// const CardImage = styled.div(props => [
//   `background-image: url("${props.imageSrc}"); min-width: fit-content;
//   `,
//   tw`h-full sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-3xl`
// ]);



export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
   let ind = 0;

  return (
    <Container id="our-work">
      <Content>
        <Subheading>Checkout our channel for detailed videos</Subheading>
        <HeadingWithControl>
          <Heading>Youtube</Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
            <Card style={{marginLeft: "60px"}}  key={ind++}>
              <iframe style={{ width: "32rem", height: "18rem"}} loading="lazy"  src="https://www.youtube.com/embed/42csJU6STAs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Card>
            <Card style={{marginLeft: "60px"}}  key={ind++}>
              <iframe style={{ width: "32rem", height: "18rem"}} loading="lazy"  src="https://www.youtube.com/embed/f4OY8zKwPC0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Card>
            <Card style={{marginLeft: "60px"}}  key={ind++}>
              <iframe style={{ width: "32rem", height: "18rem"}} loading="lazy" src="https://www.youtube.com/embed/b3kyFQTG190" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Card>
        </CardSlider>
      </Content>
    </Container>
  );
};

