import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/pharmadocx/whiteLogo.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocationArrow } from '@fortawesome/free-solid-svg-icons'

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`ml-2 border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;
const MarginText = tw.span`ml-2`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;
const BankDetailsText = tw.h5`text-lg font-black tracking-wider text-gray-100`;

const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;
const CompanyDescription = tw.p`mt-4 max-w-sm font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-8 pt-4 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

export default () => {
  return (
    <Container>
      <Content>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg alt="pharmadocx logo" src={LogoImage} />
            <LogoText>Pharmadocx Consultants</LogoText>
          </LogoContainer>
          <CompanyDescription>
            <FontAwesomeIcon icon={faLocationArrow} /> 
            <MarginText>             
               Sonipat Office - Opposite Dewan Mill, Old D.C. Road Sonepat - 131001 Haryana, India
            </MarginText>
          </CompanyDescription>
          <CompanyDescription>
          <FontAwesomeIcon icon={faLocationArrow} />  
          <MarginText>            
               Delhi Office - G-12, Pearls Best Heights-I, Netaji Subhash Place, Delhi, 110034
          </MarginText>
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/Pharmadocx">
              <FacebookIcon />
            </SocialLink>
            {/* <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink> */}
            <SocialLink href="https://www.youtube.com/channel/UCvFdSapxulg01uGgm6E9Q5A">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <WideColumn>
          <LogoContainer>
            {/* <LogoImg src={LogoImage} /> */}
            <BankDetailsText>Bank Details</BankDetailsText>
          </LogoContainer>
          <CompanyDescription>
          Account Name - Pharmadocx<br></br>
          Account No. - 030205001293<br></br>
          RTGS/IFSC Code : ICIC0000302<br></br>
          <br></br>
          Bank Name: ICICI Bank<br></br>
          Branch: Atlas Road, Model Town, Sonipat-131001 (Haryana)<br></br>
          <br></br>
          GST No. 06ARFPS0479R1ZZ<br></br>
          </CompanyDescription>
        </WideColumn>
       
        <Column>
          <ColumnHeading>Contact Details</ColumnHeading>
          <LinkList>
            <LinkListItem>
            <FontAwesomeIcon icon={faPhone} />              
            <Link href="tel:9896133556">+91 9896133556</Link>
            </LinkListItem>
            <LinkListItem>
              <FontAwesomeIcon icon={faPhone} />              
              <Link href="tel:9996859227">+91 9996859227</Link>
            </LinkListItem>
            <LinkListItem>
              <FontAwesomeIcon icon={faPhone} />              
              <Link href="tel:01302200630">+0130 2200630</Link>
            </LinkListItem>
            <LinkListItem>
              <FontAwesomeIcon icon={faEnvelope} />              
              <Link href="mailto:contact@pharmadocx.com">contact@pharmadocx.com</Link>
            </LinkListItem>
            <LinkListItem>
              <FontAwesomeIcon icon={faEnvelope} />              
              <Link href="mailto:yd@pharmadocx.com">yd@pharmadocx.com</Link>
            </LinkListItem>
            {/* <LinkListItem>
              <Link href="#">Youtube</Link>
            </LinkListItem> */}
          </LinkList>
        </Column>
      </FiveColumns>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
