import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeadingH1 } from "components/misc/Headings";
import { Helmet } from "react-helmet";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeadingH1)`font-black mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-left`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 text-left`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 text-left text-primary-600`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 text-left`}
  }
  ul {
    ${tw`list-disc list-inside text-left`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-1 inline leading-normal text-left`}
      }
    }
  }
`;
export default ({ headingText = "Documentation" }) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
          <title>Documentation Consultancy - Pharmadocx</title>
          <meta name="description" content="Making a document takes a lot of precious time of your technical
              staff. We can check your document & help you improve them."/>
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            {/* <p>Last updated: April 21, 2020</p> */}

            <p>
              Making a document takes a lot of precious time of your technical
              staff. To help you overcome your problem Pharmadocx has come
              forward. We can check your document & help you improve them. We
              can also provide you ready made documents, also documents with
              name of your company, your machine, your products, your Chemist
              name etc. so that you have to just sign & the record is ready.
              Various records CD,s & other CD,s offered by us are as following:-
            </p>

            <h2>List Of Data CDs</h2>

            
            <ul>
              <li>
                <p>
                  Complete Schedule 'M' (GMP) documents for Allopathic Plants
                  which include all SOP's, Master Formula, Log Books, Validation
                  Performa, AHU Validation Record, BPR, PM, RM, Method of
                  analysis, self inspection training etc.
                </p>
              </li>
              <li>
                <p>
                  Complete Schedule 'T' (GMP) documents for Ayurvedic Plants
                  which include all SOP's, Master Formula, Log Books, Validation
                  Performa, AHU Validation Record, BPR, PM, RM, Method of
                  analysis, self inspection training etc.
                </p>
              </li>
              <li>
                <p>
                  Complete Schedule 'M' (GMP) documents for Homeopathic Plants
                  which include all SOP's, Master Formula, Log Books, Validation
                  Performa, AHU Validation Record, BPR, PM, RM, Method of
                  Analysis, self inspection training etc.CD of documents
                  required for WHO GMP certificate
                </p>
              </li>
              <li>
                <p>
                  SOP (Standard Operating Procedures) for Formulations viz.
                  Tablets, Capsules, Liquid Orals, Dry Syrup, External
                  Application, Small Volume Parenterals, Large Volume
                  Parenterals.
                </p>
              </li>
              <li>
                <p>
                  SOP (Standard Operating Procedures) for Ayurvedic Company.
                </p>
              </li>
              <li>
                <p>
                  SOP (Standard Operating Procedures) for Homoeopathic Company.
                </p>
              </li>
              <li>
                <p>
                  SOP ( Standard Operating Procedures) for Bulk Drugs Company
                </p>
              </li>
              <li>
                <p>
                  SOP ( Standard Operating Procedures) for Cosmetics Company
                </p>
              </li>
              <li>
                <p>
                  Formulation CD containing formulation for Tablets, Capsules,
                  Syrup, Dry Syrup, Creams, Ointments, Gels, Shampoos, Liquid
                  Injections, Eye Drops, Ear Drops, Nasal Drops.
                </p>
              </li>
              <li>
                <p>
                  Formulation CD for Cosmetics Plant Viz. Deodorant, Perfume,
                  Cream, Sunscreen Lotion, Hair Dying Powder etc., Talcum
                  Powder, Compact Powder, Nail Polishes etc.
                </p>
              </li>
              <li>
                <p>Validation Protocols required for WHO GMP</p>
              </li>
              <li>
                <p>
                  Protein powder, Syrup, food tablet, food capsule project
                  report
                </p>
              </li>
              <li>
                <p>Medicated Soap Project Report</p>
              </li>
              <li>
                <p>Medicated Tooth paste project reports with formulation</p>
              </li>
              <li>
                <p>Allopathic medicine factory project report</p>
              </li>
              <li>
                <p>
                  Contact lens solution & spectacle cleaning solution project
                  report
                </p>
              </li>
              <li>
                <p>Export Business Start up Package for Pharmaceuticals</p>
              </li>
              <li>
                <p>Method of analysis of Raw Material.</p>
              </li>
              <li>
                <p>
                  Method of analysis of Packing Material like Label, Box,
                  bottle, Vial, Ampoule, Dropper, Shrink, Foil, PVC, Inserts
                  etc.
                </p>
              </li>
              <li>
                <p>
                  Method of analysis of finished goods like Tablets, Capsule,
                  injection, Ointment, Cream Dry Syrup, Liquid Oral, Soaps etc.
                </p>
              </li>
              <li>
                <p>
                  Format of WHO GMP check list used for inspecting a firm by
                  Drugs Inspector
                </p>
              </li>
              <li>
                <p>Drug Master File</p>
              </li>
              <li>
                <p>IQ, OQ, PQ.</p>
              </li>
              <li>
                <p>
                  Email addresses of Indian Manufactures, Marketing Companies,
                  Export Houses of India & Oversees Buyers.
                </p>
              </li>
              <li>
                <p>
                  Project report of any Pharmaceutical, Cosmetics, Medical
                  Device, Surgical Dressing etc. plant.
                </p>
              </li>
            </ul>

            <h2>List Of Audio CDs In Mp3 Format</h2>

            <ul>
              <li>
                <p>Audio CD of Drugs & Cosmetics Act & Rules</p>
              </li>

              <li>
                <p>
                  Audio CD of Schedule ‘M’ (Free with Drugs & Cosmetic Act).
                </p>
              </li>

              <li>
                <p>
                  Audio CD of WHO GMP guidelines (Related to Technical report
                  series.)
                </p>
              </li>

              <li>
                <p>Audio CD of USFDA guidelines</p>
              </li>

              <li>
                <p>Audio CD of Hindi SOP</p>
              </li>

              <li>
                <p>
                  Audio CD of analysis procedures of Pharmacopoeia (all topics
                  of analysis given in Pharmacopoeia like Determination of
                  Boiling Point, Test for BET, Test for Sterility, how to
                  perform Dissolution test for Tablet, Determination of Acid
                  Insoluble Ash etc. are covered in audio CD.
                </p>
              </li>
            </ul>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
