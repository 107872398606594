import "style.css"
import "tailwindcss/dist/base.css"
import './App.css';
import Home from "pages/Home.js";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AboutUs from "pages/AboutUs";
import Documentation from "pages/Documentation";
import DrugLicense from "pages/DrugLicense";
import PlantSetupConsultancy from "pages/PlantSetupConsultancy";
import ProjectReport from "pages/ProjectReport";
import ContactUs from "pages/ContactUs";
import Downloads from "pages/Downloads"
import BlogIndex from "pages/BlogIndex";
import Gloves from "pages/posts/Gloves";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/Documentation" component={Documentation} />
          <Route exact path="/DrugLicense" component={DrugLicense} />
          <Route exact path="/downloads" component={Downloads} />
          <Route exact path="/blog" component={BlogIndex}></Route>
          <Route exact path="/latex-nitrile-gloves-cdsco-medical-device-license" component={Gloves}></Route>
          <Route
            exact
            path="/plantsetup-consultancy"
            component={PlantSetupConsultancy}
          />
          <Route exact path="/ProjectReport" component={ProjectReport} />
          <Route exact path="/" component={App} />
          <Route component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  )
  // return (
  //   <Home />
  // )
}

export default App;
