import React from 'react'
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithBackground";
import Header from "../components/headers/light";
import TwoColumnWithVideo from 'components/hero/TwoColumnWithVideo';

export default function Downloads() {
  return (
    <AnimationRevealPage disabled>
            <Helmet>
                <title>Downloads</title>
                <meta name="description" content="Pharmadocx is a Consultation Firm serving Allopathic, Ayurvedic, Orthopedic, Cosmetics, Medical Devices, Machine Manufacturers & Food industry."/>
            </Helmet>
            <Header />
            <TwoColumnWithVideo />
            <Footer />
        </AnimationRevealPage>
  )
}
