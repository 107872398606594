import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeadingH1 } from "components/misc/Headings";
import { Helmet } from "react-helmet";


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeadingH1)`font-black mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-left`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 text-left`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 text-left text-primary-600`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 text-left`}
  }
  ul {
    ${tw`list-disc list-inside text-left`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-0 inline leading-normal text-left`}
      }
    }
  }
`;
export default ({ headingText = "Drug License" }) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
          <title>Drug License Consultancy</title>
          <meta name="description" content="Our regulatory team is very efficient & Our Team can help you to
              get you licence from State Drugs Controller as well as CDSCO /
              DCGI."/>
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            {/* <p>Last updated: April 21, 2020</p> */}

            <p>
              Our regulatory team is very efficient & Our Team can help you to
              get you licence from State Drugs Controller as well as CDSCO /
              DCGI. Our expert staff does liasoning work & get done following
              work for you.
            </p>

            <h2>License Work</h2>
            <ul>
              <li>
                <p>Audio CD of Drugs & Cosmetics Act & Rules</p>
              </li>

              <li>
                <p>
                  Pharmaceutical Drug Manufacturing Licence, Ayurvedic
                  Manufacturing Licence, Homoeopathic Drug Manufacturing Licence
                </p>
              </li>
              <li>
                <p>Loan Licence</p>
              </li>
              <li>
                <p>Cosmetics Manufacturing Licence</p>
              </li>
              <li>
                <p>Medical Devices Licence</p>
              </li>
              <li>
                <p>Repacking Licence</p>
              </li>
              <li>
                <p>Surgical Dressing Licence</p>
              </li>
            </ul>

            <h2>Other Work</h2>

            <ul>
              <li>
                <p>
                  Renewal of Manufacturing License & Whole Sale License: - Our
                  Team Prepare the file for renewal of license, apply it, get
                  your firm inspected, follow application at Drug Office &
                  finally renew your license.
                </p>
              </li>

              <li>
                <p>
                  Issue of FSC ( Free Sale Certificates) COPP (Certificate of
                  Pharmaceutical Products), Non Conviction Certificate, Validity
                  Certificate of Licence, Performance Certificate.
                </p>
              </li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
