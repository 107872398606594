import React, {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
// import DesignIllustration from "../../images/design-illustration-2.svg";
import DesignIllustration from "../../images/pharmadocx/machines.jpg";
// import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import CustomersLogoStripImage from "../../images/pharmadocx/ClientLogos.png";



const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
  

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;


export default ({ roundedHeaderButton }) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  return (
    <>
      {/* <Header roundedHeaderButton={roundedHeaderButton} /> */}
      {/* <TopContactInfo></TopContactInfo> */}
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
            Pharmaceuticals, Medical Devices, Cosmetics & WHO-GMP <span tw="text-primary-500">Consultation Services</span>
            </Heading>
            <Paragraph>
            We provide consultancy services for setting up <b> Allopathic, Ayurvedic, Cosmetics, Medical Devices & Health Supplements/Nutraceuticals Industries in India. </b> 
            We provide regulatory services for grant of Drugs and Cosmetics Manufacturing License, Medical Devices Manufacturing License, etc.
            {/* We help you in setting up your business with the right people, the right products and the right processes. */}
            </Paragraph>
            {/* <Actions>
              <input type="text" placeholder="Your E-mail Address" />
              <button>Get Started</button>
            </Actions> */}
            {/* <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIcon />
                  <FeatureText>{feature}</FeatureText>
                </Feature>
              ))}
            </FeatureList> */}
            <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        {/* <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <img style={{width: "100%"}} src="../../images/pharmadocx/factory1.jpg"></img>
            <ResponsiveVideoEmbed url={"../../images/pharmadocx/factory1.jpg"} tw="w-full" />
          </div>
        </StyledModal> */}
      </Container>
    </>
  );
};
