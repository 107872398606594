import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, SectionHeadingH1, Subheading as SubheadingBase } from "components/misc/Headings.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faBuilding } from '@fortawesome/free-solid-svg-icons'

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-4/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-8/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const HeadingH1 = tw(SectionHeadingH1)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed `


export default ({
  subheading = "Feel free to reach out to us!",
  heading = <>Contact Us</>,
  // heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = ["Head Office - New York, NY", "Delhi Office - New York, NY"],
  submitButtonText = "Contact Me",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  bigHeading = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id="contact-us">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            {!bigHeading ? (<Heading>{heading}</Heading>) : (<HeadingH1>{heading}</HeadingH1>)}
            <Description><FontAwesomeIcon icon={faPhone} /> 9896133556, 9996859227</Description>
            <Description><FontAwesomeIcon icon={faEnvelope} /> contact@pharmadocx.com, yd@pharmadocx.com </Description>
            <Description><FontAwesomeIcon icon={faBuilding} /> Head Office - Opposite Dewan Mill, Old D.C. Road, Sonepat, Haryana 131001 </Description>
            <Description><FontAwesomeIcon icon={faBuilding} /> Delhi Office - G-12, Pearls Best Heights-I, Netaji Subhash Place, Delhi 110034</Description>
            {/* <Form action={formAction} method={formMethod}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
