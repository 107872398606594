import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeadingH1 } from "components/misc/Headings";
import { Helmet } from "react-helmet";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeadingH1)`font-black mb-10`;
const Table = tw.table`items-center mb-10 mx-auto border-separate max-w-screen-xl w-full `;
const TableRow = tw.tr`items-start bg-gray-200`;
const TableRowWithBorder = tw.tr`items-start `;
const TableData = tw.td`h-12 px-4 sm:px-2 text-left`;
const TableDataDate = tw.td`h-12`;
const TableHeadingLeft = tw.th`font-bold text-gray-100 text-lg md:text-xl border border-gray-300 rounded-l-lg bg-primary-500 h-12`;
const TableHeadingCenter = tw.th`font-bold text-gray-100 text-lg md:text-xl border border-gray-300 bg-primary-500 h-12`;
const TableHeadingRight = tw.th`font-bold text-gray-100 text-lg md:text-xl border border-gray-300 rounded-r-lg bg-primary-500 h-12`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose text-left`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 text-left`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8 text-left text-primary-600`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 text-left`}
  }
  ul {
    ${tw`list-disc list-inside text-left`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-1 inline leading-normal text-left`}
      }
    }
  }
  ol {
    ${tw`list-disc list-inside text-left`}
    li {
      ${tw`ml-2 mb-3 text-left`}
      p {
        ${tw`mt-1 inline leading-normal text-left`}
      }
    }
  }
`;
export default ({
  headingText = "Latex and Nitrile Gloves CDSCO Medical Device License (MD5 MD15)",
}) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Gloves Medical Device License FAQs - Pharmadocx</title>
        <meta
          name="description"
          content="We answer all the questions you might have regarding the medical device license procedure of Gloves."
        />
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: February 13, 2023</p>

            <p>
              In order to manufacture and import gloves, you need to get a license from CDSCO. Here you will
              find answers to various questions on latex and nitrile gloves license
              requirements.
            </p>
            
            <img src="https://images.unsplash.com/photo-1598300188480-626f2f79ab8d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"></img>
            
            <h2>We will answer the following questions - </h2>
            <br></br>
            <b>
              <u>
                <ul>
                  <li>
                    <a href="#what-are-the-various-types-of-gloves-as-per-cdsco">
                      What are the various types of gloves as per CDSCO?
                    </a>
                  </li>
                  <li>
                    <a href="#what-are-the-requirements-to-import-and-manufacture-class-a-medical-gloves">
                      What are the requirements for import and manufacture of
                      Class A gloves?
                    </a>
                  </li>
                  <li>
                    <a href="#what-are-the-requirements-for-manufacture-of-latex-and-non-latex-surgical-gloves">
                      What are the requirements for manufacture of latex & non
                      latex surgical gloves?
                    </a>
                  </li>
                  <li>
                    <a href="#what-are-the-requirements-for-import-of-latex-and-non-latex-surgical-gloves">
                      What are the requirements for import of latex & non-Latex
                      surgical gloves?
                    </a>
                  </li>
                  <li>
                    <a href="#what-is-the-government-fee-for-manufacturing-license-of-class-b-latex-surgical-gloves-nitrile-surgical-gloves-on-md5">
                      What is the government fee for manufacturing license on MD
                      5?
                    </a>
                  </li>
                  <li>
                    <a href="#what-is-the-government-fee-for-import-license-of-class-b-latex-surgical-gloves-nitrile-surgical-gloves-on-md15">
                      What is the government fee for import license of Class B
                      latex Surgical Gloves, Nitrile Surgical Gloves on MD15?
                    </a>
                  </li>
                  <li>
                    <a href="#what-is-the-government-fee-for-registration-for-import-or-manufacture-class-a-latex-examination-gloves-nitrile-examination-gloves-non-latex-medical-examination-gloves">
                      What is the government fee for registration for import or
                      manufacture class A (Latex Examination gloves, nitrile
                      examination gloves, non-latex medical examination gloves)?
                    </a>
                  </li>
                </ul>
              </u>
            </b>
            <h2 id="what-are-the-various-types-of-gloves-as-per-cdsco">
              What are the various types of gloves as per CDSCO?
            </h2>
            <p>
              CDSCO with its notification date 21st September 2021 under the
              heading “Personal Protective equipment” has classified gloves into
              5 categories with Latex Examination Gloves, Non-latex Examination
              gloves, Radiation Protection gloves under class A and Latex
              Surgical Gloves, Non-latex Surgical Gloves under Class B.
              <br></br>
              Description as per CDSCO Notification is as follows.
            </p>
            <Table>
              <TableRow>
                <TableHeadingLeft width="50%">Type of Glove</TableHeadingLeft>
                <TableHeadingRight width="50%">Description</TableHeadingRight>
              </TableRow>
              <TableRow>
                <TableData>Latex medical examination glove</TableData>
                <TableData>
                  Natural rubber gloves used to protect the patient and users
                  from cross infection during examination, check-up, treatment
                  and handling of contaminated medical materials except for
                  Surgery
                </TableData>
              </TableRow>
              <TableRow>
                <TableData>Non-latex Medical examination glove</TableData>
                <TableData>
                  Gloves made of synthetic materials, and used to protect the
                  patient and users from cross infection during examination,
                  check-up, treatment and handling of contaminated medical
                  materials except for surgery
                </TableData>
              </TableRow>
              <TableRow>
                <TableData>Radiation protection gloves</TableData>
                <TableData>
                  A personal protection device that completely protects the
                  hands of the operator and other personnel from unnecessary
                  exposure to primary radiation and scattered radiation
                  associated with diagnosis and therapeutic measures.
                </TableData>
              </TableRow>
              <TableRow>
                <TableData>Latex surgical glove</TableData>
                <TableData>
                  Natural rubber-based glove intended to protect the patient and
                  wearer from cross infection when used in medical or dental
                  surgery.
                </TableData>
              </TableRow>
              <TableRow>
                <TableData>Non-latex surgical glove</TableData>
                <TableData>
                  Synthetic material glove intended to protect the patient and
                  wearer from cross infection when used in medical or dental
                  surgery
                </TableData>
              </TableRow>
            </Table>
            <h2 id="what-are-the-requirements-to-import-and-manufacture-class-a-medical-gloves">
              What are the requirements to import and manufacture Class A
              medical gloves?
            </h2>
            <p>
              Pharmadocx consultants can guide you in registration for Import
              and Manufacture of Latex Examination Gloves, Nitrile Examination
              gloves, non-latex examination gloves. For registration for Class A
              products following documents are required.{" "}
            </p>
            <ol>
              <li>Aadhar Card</li>
              <li>PAN Card</li>
              <li>GST Certificate</li>
              <li>Certificate of incorporation</li>
              <li>ISO 13485</li>
              <li>Free Sale Certificate</li>
            </ol>
            <p>
              We at Pharmadocx consultants can guide you in this process for
              registration of various types of examination gloves. The
              government fee for the registration of gloves of class A on the
              online portal is INR 0. Once the registration is completed, A file
              number is generated which shall be printed on the label of the
              product being imported. It is important to note that Sterile
              gloves cannot be imported by the means of registration.{" "}
            </p>

            <h2 id="what-are-the-requirements-for-manufacture-of-latex-and-non-latex-surgical-gloves">
              What are the requirements for manufacture of latex & non latex
              surgical gloves?
            </h2>
            <p>
              In order to manufacture Latex surgical glove (Natural rubber-based
              glove intended to protect the patient and wearer from cross
              infection when used in medical or dental surgery) or Non Latex
              surgical gloves (Synthetic material glove intended to protect the
              patient and wearer from cross infection when used in medical or
              dental surgery) or Nitrile surgical gloves (Nitrile based glove
              intended to protect the patient and wearer from cross infection
              when used in medical or dental surgery) a license is required on
              Form MD5. The government fees for the license to manufacture
              sterile gloves of class B is Rs 5000 (license fee) and Rs 500 (per
              product). <br></br> Prior to grant of this license an audit is
              conducted of your premises as per the requirements of medical
              device rules, 2017 & ISO 13485:2016. The checklist for the
              documents required for application for license to manufacture
              gloves can be downloaded using the link given below. The entire
              process from the time of application takes to grant of license
              takes about 3 months to complete. <br></br>
              <a style={{ color: "blue" }} href="/downloads">
                Checklist Download Link
              </a>
            </p>

            <h2 id="what-are-the-requirements-for-import-of-latex-and-non-latex-surgical-gloves">
              What are the requirements for import of latex & non latex surgical
              gloves?
            </h2>
            <p>
              In order to import Latex surgical glove (Natural rubber-based
              glove intended to protect the patient and wearer from cross
              infection when used in medical or dental surgery) or Non Latex
              surgical gloves (Synthetic material glove intended to protect the
              patient and wearer from cross infection when used in medical or
              dental surgery) or Nitrile surgical gloves (Nitrile based glove
              intended to protect the patient and wearer from cross infection
              when used in medical or dental surgery) a license is required on
              Form MD15. The government fees for the license to manufacture
              sterile gloves of class B is USD 2000$ (license fee) and USD 1000$
              (per product). <br></br> In case of import audit of the premises
              is not conducted. The checklist for documents required in order to
              import latex surgical gloves, non latex surgical gloves, nitirile
              surgical can be downloaded from the link given below. The entire
              process from the time of application takes to grant of license to
              import Latex Surgical gloves, Nitrile Examination gloves takes
              about 4 months to complete.{" "} <br></br>
              <a style={{ color: "blue" }} href="/downloads">
                Checklist Download Link
              </a>
            </p>

            <h2 id="what-is-the-government-fee-for-manufacturing-license-of-class-b-latex-surgical-gloves-nitrile-surgical-gloves-on-md5">
              What is the government fee for manufacturing license of Class B
              latex Surgical Gloves, Nitrile Surgical Gloves on MD5?
            </h2>
            <p>
              The government fee for license to manufacture Class B (latex
              Surgical Gloves, Nitrile Surgical Gloves) or sterile gloves is Rs
              5000 for license and Rs 500 Per product.{" "}
            </p>
            <Table>
              <TableRow>
                <TableHeadingLeft width="50%">Product</TableHeadingLeft>
                <TableHeadingRight width="50%">Fees</TableHeadingRight>
              </TableRow>
              <TableRow>
                <TableData>
                  <b>
                    Latex Surgical Glove <br></br> Or <br></br> Nitrile Surgical
                    Glove <br></br> Or <br></br> Sterile Gloves{" "}
                  </b>
                </TableData>
                <TableData>
                  Rs 5,000 License Fees + Rs 500 per product
                </TableData>
              </TableRow>
            </Table>

            <h2 id="what-is-the-government-fee-for-import-license-of-class-b-latex-surgical-gloves-nitrile-surgical-gloves-on-md15">
              What is the government fee for import license of Class B latex
              Surgical Gloves, Nitrile Surgical Gloves on MD15?
            </h2>
            <p>
              The government fee for license to manufacture Class B (latex
              Surgical Gloves, Nitrile Surgical Gloves) or sterile gloves is Rs
              5000 for license and Rs 500 Per product.
            </p>
            <Table>
              <TableRow>
                <TableHeadingLeft width="50%">Product</TableHeadingLeft>
                <TableHeadingRight width="50%">Fees</TableHeadingRight>
              </TableRow>
              <TableRow>
                <TableData>
                  <b>
                    Latex Surgical Glove <br></br> Or <br></br> Nitrile Surgical
                    Glove <br></br> Or <br></br> Sterile Gloves{" "}
                  </b>
                </TableData>
                <TableData>
                  USD 2000$ (Approx INR 1,60,000) License Fees + USD 1000$
                  (Approx INR 80,000) per product
                </TableData>
              </TableRow>
            </Table>

            <h2 id="what-is-the-government-fee-for-registration-for-import-or-manufacture-class-a-latex-examination-gloves-nitrile-examination-gloves-non-latex-medical-examination-gloves">
              What is the government fee for registration for import or
              manufacture class A (Latex Examination gloves, nitrile examination
              gloves, non-latex medical examination gloves)
            </h2>
            <p>
              There is no government fee for registration of Latex Examination
              gloves, nitrile examination gloves, non-latex medical examination
              gloves of Class A for both manufacturing and import.{" "}
            </p>

            <p>
              <strong>
                We at Pharmadocx Consultants can provide consultation services
                to firms looking for license to either manufacture or import
                Latex Examination gloves, Nitrile Examination Glove, Radiation
                Protection gloves, Latex Surgical Gloves, Nitrile Surgical
                gloves and all other types of Class A and Class B Gloves. We
                have been providing consultancy to various firms throughout for
                over 15 years. We have served over 150 medical device companies
                in our history. We ensure that the clients face no difficulties
                or hurdles In the way to manufacture and import any medical
                device. <br></br>
                <br></br>
                You can contact us on{" "}
                <a style={{ color: "blue" }} href="tel:9996859227">
                  9996859227
                </a>{" "}
                or at{" "}
                <a style={{ color: "blue" }} href="mail:contact@pharmadocx.com">
                  contact@pharmadocx.com
                </a>{" "}
                for guidance and consultation on how to manufacture and import
                gloves or any other medical devices. We will be happy to serve
                you in your endeavour.
              </strong>
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
