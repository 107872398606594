import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import MainFeature1 from "components/features/TwoColWithButton.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import TwoColSingleFeatureWithStats2 from "components/features/TwoColSingleFeatureWithStats2";
import imageSrc3 from "../images/pharmadocx/image2.jpg";
import imageSrc4 from "../images/pharmadocx/image3.jpg";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
const defaultStatistics = [
  {
    key: "Drug Licenses",
    value: "200+",
  },
  {
    key: "Experience",
    value: "27+ years",
  },
  {
    key: "Clients",
    value: "500+",
  },
  {
    key: "Medical Device MD-5,MD-9 Licenses",
    value: "100+",
  },
  {
    key: "Cosmetics Licenses",
    value: "50+",
  },
];
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Founded in 2007, Pharmadocx is the industry leader providing consultancy services for setting up Allopathic, Ayurvedic, Cosmetics & Medical Devices factories"/>
      </Helmet>
      <Header />
      <MainFeature1
        bigHeading={true}
        subheading={<Subheading>About Pharmadocx</Subheading>}
        heading="Pharmaceuticals, Medical Devices, Cosmetics & WHO-GMP Consultant"
        buttonRounded={false}
        description="Founded in 2007, Pharmadocx is India based firm providing consultation services to Pharmaceutical Formulation, API, Homoeopathic, Ayurvedic, Unani & Siddha Medicine, Medical Devices, Orthopedic Implants, Surgical Dressing, Medical Oxygen, Diagnostic Kits, Cosmetics Factories, FSSAI & Quality Certification."
        primaryButtonText="Contact Us"
        showButton
        primaryButtonUrl="/contact-us"
        imageSrc={imageSrc3}
      />
      <MainFeature1
        subheading={<Subheading>Our Activities</Subheading>}
        heading="One stop shop for your plant setup consultation needs"
        buttonRounded={false}
        primaryButtonUrl="/#services"
        description="We do Designing of new plant by providing Civil Auto-cad Drawing, Electric Drawing, Plumbing Drawing, HVAC Drawing, Equipment Drawing. We provide documents consultation & plant up gradation services to get WHO-GMP & COPP Certificates. We provide all types of SOP’s Validation Documents, Site Master File, Device Master File, Pharma Stationary etc. We provide Regulatory Services for Grant of Drug Manufacturing License on Form-25 & Form-28, Retail & Whole Sale License on Form-20 & 21 etc., FSSAI License."
        primaryButtonText="Check The Services We Offer"
        showButton
        imageSrc={imageSrc4}
        textOnLeft={false}
      />
      <TwoColSingleFeatureWithStats2
      subheading = "Our Track Record"
      description = "We have years of experience helping a wide array of clients setting up their factories. Our firm has set-up maximum number of plants in North India. Our numbers speak for themselves."
      primaryButtonText = "Learn More"
      primaryButtonUrl = "https://timerse.com"
      statistics = {defaultStatistics}
      >
      </TwoColSingleFeatureWithStats2>
      <ContactUsForm></ContactUsForm>
      <Footer />
    </AnimationRevealPage>
  );
};
