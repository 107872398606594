import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithBackground";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import ContactUsHead from "components/forms/TwoColContactUsWithIllustration"
import Header from "../components/headers/light";

class ContactUs extends Component {

    render() {
        return <AnimationRevealPage disabled>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact us to setup your own pharmaceutical, cosmetics, medical devices factory contact@pharmadocx.com 9996859227" />
            </Helmet>
            <Header />
            <ContactUsHead bigHeading={true} />
            <ContactUsForm />
            
            <Footer />
        </AnimationRevealPage>
    }
}

export default ContactUs;